import React from 'react';
import BookList from '../components/Books/BookList';
import {data} from '../components/Books/data';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

export default function books() {
  const books2019 = data.filter(book => book.year_read === 2019);
  const books2020 = data.filter(book => book.year_read === 2020);

  return (
    <Layout>
      <SEO title="Books read" />
      <BookList title="2020" books={books2020} status="finished" />
      <BookList
        subtitle="Currently reading"
        books={books2020}
        status="reading"
      />
      <BookList
        subtitle="Future reading"
        books={[...books2020, ...books2019]}
        status="planned"
      />
      <BookList title="2019" books={books2019} status="finished" />
    </Layout>
  );
}
