import styled from 'styled-components';

export const List = styled('ul')`
  color: white;
`;

export const ListItem = styled('li')`
  color: white;
  padding: 10px;
`;

export const BookTitle = styled('h3')`
  color: white;
  display: inline-block;
  font-size: 16px;
`;

export const Author = styled('h4')`
  color: white;
  display: inline-block;
  font-size: 11px;
`;

export const Title = styled('h1')`
  color: white;
`;

export const Subtitle = styled('h2')`
  color: white;
  font-size: 18px;
  padding-top: 30px;
`;

export const LinkToBook = styled('a')`
  text-decoration: none;
`;
