export interface Book {
  title: string;
  author: string;
  recommend: number;
  year_read: number;
  status: 'finished' | 'reading' | 'planned';
  link: string;
}

export const data: Book[] = [
  {
    title: 'The Everything Store',
    author: 'Brad Stone',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/17660462-the-everything-store?ac=1&from_search=true&qid=Nq2F9HKIXj&rank=1',
  },
  {
    title: 'Trump: The Art of the Deal',
    author: 'Donald J. Trump, Tony Schwartz',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/1032.Trump?ac=1&from_search=true&qid=n0vS1Oid2E&rank=2',
  },
  {
    title: 'Astrophysics for People in a Hurry',
    author: 'Neil deGrasse Tyson',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/32191710-astrophysics-for-people-in-a-hurry?ac=1&from_search=true',
  },
  {
    title: 'Elon Musk: Tesla, SpaceX, and the Quest for a Fantastic Future',
    author: 'Ashlee Vance',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/25541028-elon-musk?ac=1&from_search=true',
  },
  {
    title: 'The 7 Habits of Highly Effective People',
    author: 'Stephen R. Covey',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/36072.The_7_Habits_of_Highly_Effective_People?ac=1&from_search=true',
  },
  {
    title: 'How to Win Friends and Influence People',
    author: 'Dale Carnegie',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/4865.How_to_Win_Friends_and_Influence_People?ac=1&from_search=true',
  },
  {
    title: 'Let Go',
    author: 'Pat Flynn',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/17847712-let-go?ac=1&from_search=true',
  },
  {
    title: 'Ego is the enemy',
    author: 'Ryan Holiday',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/27036528-ego-is-the-enemy?ac=1&from_search=true',
  },
  {
    title: 'I will teach you to be rich',
    author: 'Ramit Sethi',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/40591670-i-will-teach-you-to-be-rich?ac=1&from_search=true',
  },
  {
    title: 'The Airbnb Story',
    author: 'Leigh Gallagher',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/32198220-the-airbnb-story?ac=1&from_search=true',
  },
  {
    title: 'Think and Grow Rich',
    author: 'Napoleon Hill',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/30186948-think-and-grow-rich?ac=1&from_search=true',
  },
  {
    title: 'The Definitive Book of Body Language',
    author: 'Allan Pease,  Barbara Pease',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/262731.The_Definitive_Book_of_Body_Language?ac=1&from_search=true',
  },
  {
    title: 'Sapiens',
    author: 'Yuval Noah Harari',
    recommend: 5,
    year_read: 2019,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/23692271-sapiens?ac=1&from_search=true',
  },
  {
    title: 'Principles: Life and Work',
    author: 'Ray Dalio',
    recommend: 5,
    year_read: 2019,
    status: 'planned',
    link:
      'https://www.goodreads.com/book/show/34536488-principles?ac=1&from_search=true',
  },
  {
    title: 'Work Rules!',
    author: 'Laszlo Bock',
    recommend: 5,
    year_read: 2019,
    status: 'planned',
    link:
      'https://www.goodreads.com/book/show/22875447-work-rules?ac=1&from_search=true',
  },
  {
    title: 'The Headspace Guide to Meditation & Mindfulness',
    author: 'Andy Puddicombe',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/27414493-the-headspace-guide-to-meditation-mindfulness?ac=1&from_search=true&qid=BFRwtvswZm&rank=1',
  },
  {
    title: 'The Clean Coder',
    author: 'Robert C. Martin',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/10284614-the-clean-coder?ac=1&from_search=true&qid=QQJCg2Lq2s&rank=1',
  },
  {
    title: 'Domain-Driven Design Distilled',
    author: 'Vaughn Vernon',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/28602719-domain-driven-design-distilled?ac=1&from_search=true&qid=CXloWbTVMA&rank=1',
  },
  {
    title: 'Bad Blood: Secrets and Lies in a Silicon Valley Startup',
    author: 'John Carreyrou',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/37976541-bad-blood?ac=1&from_search=true&qid=6YPrwG3fQc&rank=1',
  },
  {
    title: 'The Subtle Art of Not Giving a F*ck',
    author: 'Mark Manson',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/37976541-bad-blood?ac=1&from_search=true&qid=6YPrwG3fQc&rank=1',
  },
  {
    title: 'Rich Dad, Poor Dad',
    author: 'Robert T. Kiyosaki',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/69571.Rich_Dad_Poor_Dad?ac=1&from_search=true&qid=ES0eM7qrSP&rank=1',
  },
  {
    title: 'Factfulness',
    author: 'Hans Rosling',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/34890015-factfulness?ac=1&from_search=true&qid=YKaWOkuoXS&rank=1',
  },
  {
    title: 'Ultralearning',
    author: 'Scott H. Young',
    recommend: 5,
    year_read: 2020,
    status: 'reading',
    link:
      'https://www.goodreads.com/book/show/44770129-ultralearning?ac=1&from_search=true&qid=T4y2VNG38H&rank=1#',
  },
  {
    title: 'The Richest Man in Babylon',
    author: 'George S. Clason',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/1052.The_Richest_Man_in_Babylon?ac=1&from_search=true&qid=fg0sTD8YTm&rank=1',
  },
  {
    title: 'Business Adventures',
    author: 'John Brooks',
    recommend: 5,
    year_read: 2020,
    status: 'reading',
    link:
      'https://www.goodreads.com/book/show/4191136-business-adventures?ac=1&from_search=true&qid=MbQ1Kw9mGJ&rank=1',
  },
  {
    title: 'The Hidden Life of Trees',
    author: 'Peter Wohlleben',
    recommend: 5,
    year_read: 2020,
    status: 'finished',
    link:
      'https://www.goodreads.com/book/show/28256439-the-hidden-life-of-trees',
  },
];
