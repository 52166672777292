import React from 'react';
import {
  Author,
  BookTitle,
  LinkToBook,
  List,
  ListItem,
  Subtitle,
  Title,
} from './Books.styled';
import {Book} from './data';

interface Props {
  books: Book[];
  status: 'finished' | 'reading' | 'planned';
  title?: string;
  subtitle?: string;
}

export default function BookList({title, subtitle, books, status}: Props) {
  return (
    <>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <List>
        {books
          .filter(book => book.status === status)
          .map(book => (
            <LinkToBook
              key={`${book.title}-${book.author}`}
              href={book.link}
              target="_blank"
            >
              <ListItem>
                <BookTitle>{book.title}</BookTitle>
                <Author>, by {book.author}</Author>
              </ListItem>
            </LinkToBook>
          ))}
      </List>
    </>
  );
}
